<template>
  <MobileDefault v-if="isMobile">
    <div class="flex flex-row justify-space-between items-center container-bg-white container-space-mobile-default" style="margin-bottom: 0; padding-bottom: 0">
      <h2 class="title mobile-title">
        <span class="border-left-line mobile-view"></span>
        Top từ khoá
      </h2>
<!--      <a-icon type="filter" style="margin-bottom: 8px; color: #838383"/>-->
    </div>
    <TableKeyword class="container-bg-white" style="margin: 0 12px" :load-type="null"/>
  </MobileDefault>
  <Default v-else>
    <TableKeyword class="container-bg-white" style="margin: 0 12px" :load-type="null"/>
  </Default>
</template>

<script>
import TableKeyword from "@/views/search/TableKeyword";
import MobileDefault from "@/layout/MobileDefault";
import Default from "@/layout/Default";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
export default {
  name: "TableKeywordView",
  components: {
    MobileDefault,
    Default,
    TableKeyword
  },
  mixins: [
    mixinStoreGetterGlobal
  ],
  data() {
    return {
      isLoadingDownloadCSV: false
    }
  }
}
</script>

<style scoped>

</style>
